const aboutus = [
  [
    {
      image: require("../Images/Sliders/about.jpg"),
      title: "About Us",
      content:
        "Welcome to Ayaan Faris Computers & Requisites Trading Co L.L.C – AYFA Computers. Your Trusted Partner in IT Solutions Led by the leadership of two young enthusiastic entrepreneurs and legally Established in Dubai, United Arab Emirates in the year 2021, Ayaan Faris Computers & Requisites Trading Co L.L.C is a fastest growing IT company and a rising star in the IT industry. We pride ourselves on being more than just suppliers; we would love to be your dedicated business partners in harnessing technology to drive success.",
    },
  ],
  [
    {
      title: "Who We are",
      content:
        "At Ayaan Faris Computers & Requisites Trading Co L.L.C, we are committed to delivering top-notch IT solutions tailored to meet the unique needs of our clients. As trusted suppliers of leading IT brands such as Apple, HP, Lenovo, Dell, Micron, Samsung, Western Digital, SanDisk, Transcend, Ugreen, Logitech and many more we ensure that our clients have access to the best-in-class products the industry has to offer. The geographical area of our operation includes UAE , Oman , Qatar , Bahrain , Kuwait , Saudi , Africa & CIS Countries.",
      image: require("../Images/Sliders/whoweare1.png"),
    },
  ],
  [
    {
      title: "Why Choose Us",
    },
  ],
  [
    {
      image: require("../Images/Icons/Logo13.png"),
      title: "Innovation",
      content:
        "Constantly upgrading our technology and services is essential to staying ahead of the curve of our clients.",
    },
    {
      image: require("../Images/Icons/Logo10.png"),
      title: "Quality",
      content:
        "We make sure everything we offer is top-notch, so you can rely on us for quality and performance",
    },
    {
      image: require("../Images/Icons/Logo11.png"),
      title: "Client-Centric Approach",
      content:
        "We focus on your satisfaction. Working closely with you, we deliver customized solutions that exceed expectations.",
    },
    {
      image: require("../Images/Icons/Logo14.png"),
      title: "Reliability",
      content:
        "With our commitment to promptness and efficiency, you can count on us to deliver top-notch solutions.",
    },
    {
      image: require("../Images/Icons/Logo15.png"),
      title: "Mission",
      content:
        "We are here to help you with great IT solutions that make things easier, boost productivity, and help you grow.",
    },
    {
      image: require("../Images/Icons/Logo12.png"),
      title: "Consulting and Support",
      content:
        "Get expert advice and 24/7 support to navigate the ever-changing IT landscape with confidence",
    },
  ],
];
export default aboutus;
