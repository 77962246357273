import React, { useEffect, useRef, useState } from "react";
import "../../Scss/Contactus.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop.js";
import CompletedIcon from "../../Images/Icons/completed.png";
import ContactusData from "../../Data/Contactus";
import emailjs from "@emailjs/browser";

const Contactus = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    validateEmail();
  }, [email]);
  const validateEmail = () => {
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    setIsValidEmail(regex.test(email));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vxm3dyh",
        "template_zgd3awk",
        form.current,
        "SjvDsapTTZSQugESy"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setEmail("");
          setName("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <section className="contact-page-sec">
        <div className="container">

          <div class="box">
            <h1>Retail Wholesale Exports</h1>
          </div>
          <div className="row">
            {ContactusData.map((data) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="contact-info">
                    <div className="contact-info-item">
                      <div className="contact-info-icon">
                        <img src={data.icon} alt="logo" />
                      </div>
                      <div className="contact-info-text">
                        <h2>{data.title}</h2>
                        <span>{data.content1}</span>
                        <span>{data.content2}</span>
                        <span>{data.content3}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="contact-page-form" method="post">
                <h2>Get in Touch</h2>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="single-input-field">
                        <input
                          type="text"
                          placeholder="Your Name"
                          name="user_name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="single-input-field">
                        <input
                          type="email"
                          placeholder="E-mail"
                          name="user_email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {email.length > 0 && (
                        <>
                          {isValidEmail ? (
                            <p style={{ color: "green" }}>
                              Valid email address
                            </p>
                          ) : (
                            <p style={{ color: "red" }}>
                              Invalid email address
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-md-12 message-input">
                      <div className="single-input-field">
                        <textarea
                          placeholder="Write Your Message"
                          name="message"
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                        ></textarea>
                      </div>
                    </div>
                    <div
                      className={
                        !email || !name || !message
                          ? "single_input_disable"
                          : "single-input-fieldsbtn"
                      }
                    >
                      <input
                        type="submit"
                        value="Send"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        disabled={!email || !name || !message}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-page-map">
                <iframe
                  width="100%"
                  height="250"
                  frameborder="0"
                  title="map"
                  allowfullscreen
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Al%20Marri%20Building,%20Deira,%20Dubai+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="ayfa_contactus_confirm_popup">
                  <div className="ayfa_contactus_completed">
                    <div className="ayfa_contactus_completed_icon">
                      <img src={CompletedIcon} alt="logo" />
                    </div>
                  </div>
                  <div className="ayfa_contactus_completed_text">
                    <h5>
                      Your Response has <br /> been Sent Successfully!
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contactus;
