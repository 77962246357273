const Brands = [
  [{ image: require("../Images/Sliders/Brands1.png") }],
  [
    {
      title: "BRANDS",
      content:
        "At AYFA Computers, we've established partnerships with leading electronic brands, ensuring top-tier quality and innovation in our IT solutions. Through collaborations with renowned industry players, we leverage their cutting-edge products and expertise to deliver tailored IT solutions that meet the unique needs of our clients. These strategic alliances reflect our commitment to providing best-in-class technology solutions and driving business success in the digital era.",
    },
  ],
  [
    { image: require("../Images/Brands/3M.png") },
    { image: require("../Images/Brands/Acer.png") },
    { image: require("../Images/Brands/Apple Logo.png") },
    { image: require("../Images/Brands/Autodesk.png") },
    { image: require("../Images/Brands/Benq.png") },
    { image: require("../Images/Brands/Canon.png") },
    { image: require("../Images/Brands/CoolerMaster.png") },
    { image: require("../Images/Brands/Crucial.png") },
    { image: require("../Images/Brands/Dell.png") },
    { image: require("../Images/Brands/Epson.png") },
    { image: require("../Images/Brands/Fujitsu.png") },
    { image: require("../Images/Brands/HP Logo.png") },
    { image: require("../Images/Brands/Intel.png") },
    { image: require("../Images/Brands/Jabra.png") },
    { image: require("../Images/Brands/Kingston.png") },
    { image: require("../Images/Brands/LG.png") },
    { image: require("../Images/Brands/Lenovo.png") },
    { image: require("../Images/Brands/Lexar.png") },
    { image: require("../Images/Brands/Microsoft.png") },
    { image: require("../Images/Brands/Msi.png") },
    { image: require("../Images/Brands/Panasonic.png") },
    { image: require("../Images/Brands/Pny.png") },
    { image: require("../Images/Brands/Port.png") },
    { image: require("../Images/Brands/Ricoh.png") },
    { image: require("../Images/Brands/Targus.png") },
    { image: require("../Images/Brands/Tforce.png") },
    { image: require("../Images/Brands/Toshiba.png") },
    { image: require("../Images/Brands/Transcend.png") },
    { image: require("../Images/Brands/Zotac.png") },
    { image: require("../Images/Brands/asus.png") },
    { image: require("../Images/Brands/corsair.png") },
    { image: require("../Images/Brands/logitech.png") },
    { image: require("../Images/Brands/oscoo.png") },
    { image: require("../Images/Brands/samsung.png") },
    { image: require("../Images/Brands/seagate.png") },
  ],
];
export default Brands;
