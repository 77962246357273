const Gaming = [
    [
      { image: require("../Images/LaptopDesktop/GamingCabinet1.png") },
      { image: require("../Images/LaptopDesktop/GamingCabinet2.png") },
      { image: require("../Images/LaptopDesktop/GamingCabinet3.png") },
      { image: require("../Images/LaptopDesktop/GamingCabinet4.png") },
    ],
    [
      { image: require("../Images/LaptopDesktop/Chair1.png") },
      { image: require("../Images/LaptopDesktop/Chair2.png") },
      { image: require("../Images/LaptopDesktop/Chair3.png") },

    ],
    [
      { image: require("../Images/LaptopDesktop/GamingHeadset1.png") },
      { image: require("../Images/LaptopDesktop/GamingHeadset2.png") },
      { image: require("../Images/LaptopDesktop/GamingHeadset3.png") },
      { image: require("../Images/LaptopDesktop/GamingHeadset4.png") },
    ],
  ];
  export default Gaming;
  