const businessunits = [
  {
    Image: require("../../Images/Icons/Logo.png"),
    title: "Audio Visuals",
  },
  {
    Image: require("../../Images/Icons/Logo2.png"),
    title: "Personal Storage",
  },
  {
    Image: require("../../Images/Icons/Logo8.png"),
    title: "Components",
  },
  {
    Image: require("../../Images/Icons/Logo3.png"),
    title: "Document Imaging",
  },
  {
    Image: require("../../Images/Icons/Logo4.png"),
    title: "Document Printing",
  },
  {
    Image: require("../../Images/Icons/Logo5.png"),
    title: "Consumer Softwares",
  },
  {
    Image: require("../../Images/Icons/Logo6.png"),
    title: "Gaming",
  },
  {
    Image: require("../../Images/Icons/Logo7.png"),
    title: "Video Survillance",
  },
  {
    Image: require("../../Images/Icons/Logo16.png"),
    title: "Wireless & Networking",
  },
  {
    Image: require("../../Images/Icons/Logo9.png"),
    title: "Smart ID Scanner",

  },
  {
    Image: require("../../Images/Icons/Logo17.png"),
    title: "Workstations",

  },
  {
    Image: require("../../Images/Icons/Logo18.png"),
    title: "Networking Cables",

  },

];

export default businessunits;
