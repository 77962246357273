import React from "react";
import "../../Scss/Home.scss";
import Header from "../Header/Header";
import Carousal from "../Carousal/Carousal";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Businessunitsdata from "../../Data/BusinessUnits/Businessunits";
import BrandsData from "../../Data/Brands/Brands";

const Home = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Carousal />
      <div
        className="elfsight-app-b98d4df2-fec2-4456-bec3-89aba9bb38cf"
        data-elfsight-app-lazy
      ></div>
      <div className="ayfa_main">

        <div className="ayfa_business_units">
          <div className="container">
{/* 
            <div class="box">
              <h1>Retail Wholesale Exports</h1>
            </div> */}

            <div className="ayfa_business_title">
              <h4>Business Units</h4>
            </div>

            <div className="ayfa_business_units_cardLlist">
              <div className="row">
                {Businessunitsdata.map((data) => {
                  return (
                    <div className="col-lg-3 col-md-6">
                      <div className="ayfa_business_units_card">
                        <div className="ayfa_business_units_card_logo">
                          <div className="ayfa_business_logos">
                            <img src={data.Image} alt="logo" />
                          </div>
                        </div>
                        <h3>{data.title}</h3>

                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

          </div>
        </div>

        <div className="ayfa_our_visitormanagementsystem">
          <div className="ayfa_our_visitormanagementsystem_title">
          </div>
        </div>

        <div className="ayfa_our_brands">
          <div className="container">
            <div className="ayfa_our_brands_title">
              <h4>Our Brands</h4>
            </div>
            <div className="row">
              {BrandsData.map((data) => {
                return (
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="ayfa_brand_icons">
                      <img src={data.image} alt="logo" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
