import React from "react";
import '../../Scss/PreLoader.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MouseLoader from "../../Images/Icons/MouseLoader.gif";
const PreLoader = () => {
  return (
    <>
      <div className="container">
        <div className="ayfa_Preloader_logo">
          <LazyLoadImage src={MouseLoader} />
        </div>
      </div>
    </>
  );
};

export default PreLoader;
