import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Pages/Home";
import Aboutus from "./components/Pages/Aboutus";
import Contactus from "./components/Pages/Contactus";
import LaptopDesktop from "./components/Pages/LaptopDesktop";
import PeripheralsAccessories from "./components/Pages/PeripheralsAccessories";
import Storage from "./components/Pages/Storage";
import Gaming from "./components/Pages/Gaming";
import Components from "./components/Pages/Components";
import OurTechnicalServices from "./components/Pages/OurTechnicalServices";
import Brands from "./components/Pages/Brands";
import PreLoader from "./components/PreLoader/PreLoader";
import MeetTheTeam from './components/Pages/MeetTheTeam'
const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  });

  return (
    <>
      {loading === true ? (
        <PreLoader />
      ) : (
        <BrowserRouter>
          <Routes>       
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/laptopdesktop" element={<LaptopDesktop />} />
            <Route
              path="/peripheralsaccessories"
              element={<PeripheralsAccessories />}
            />
            <Route path="/storage" element={<Storage />} />
            <Route path="/gaming" element={<Gaming />} />
            <Route path="/components" element={<Components />} />
            <Route
              path="/ourtechnicalservices"
              element={<OurTechnicalServices />}
            />
            <Route path="/brands" element={<Brands />} />
            <Route path="/meettheteam" element={<MeetTheTeam />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
