const outtechnicalservices = [
  [
    { image: require("../Images/Sliders/OurTechnicalService.jpg") },
    { title: "Our Technical Services" },
  ],   
  [   
    {
      image: require("../Images/Icons/Laptop Servicing.png"),
      title: "Laptop Repair & Servicing",
      innercontent: require("../Images/TechnicalServices/LaptopRepair and Servicing.jpg"),
    },
    {
      image: require("../Images/Icons/TelephonyPABX.png"),
      title: "IP Telephony PABX",
      innercontent: require("../Images/TechnicalServices/IP Telephone and PABX.jpg"),
    },
    {
      image: require("../Images/Icons/NetworkingSolutions.png"),
      title: "Networking Solutions",
      innercontent: require("../Images/TechnicalServices/Networking Solution.jpg"),
    },
    {
      image: require("../Images/Icons/StructuredCabling.png"),
      title: "Structured Cabling",
      innercontent: require("../Images/TechnicalServices/Structured Cabling.jpg"),
    },
    {
      image: require("../Images/Icons/SurvellienceSecurity.png"),
      title: "Surveillance & Security CCTV",
      innercontent: require("../Images/TechnicalServices/Surveillance and CCTV Security.jpg"),
    },
    {
      image: require("../Images/Icons/VideoConferencing.png"),
      title: "Video Conferencing Solutions",
      innercontent: require("../Images/TechnicalServices/VideoConferencing and Solutions.jpg"),
    },
    {
      image: require("../Images/Icons/AttendanceSystem.png"),
      title: "Attendance System & Access Control",
      innercontent: require("../Images/TechnicalServices/Attendance System and Access control.jpg"),
    },
    {
      image: require("../Images/Icons/ServerInstallation.png"),
      title: "Server Installation & Management",
      innercontent: require("../Images/TechnicalServices/ServerInstalling and Management.jpg"),
    },
    {
      image: require("../Images/Icons/CloudStorage.png"),
      title: "Cloud Storage",
      innercontent: require("../Images/TechnicalServices/Cloud Storage.jpg"),
    },
    {
      image: require("../Images/Icons/DataMigration.png"),
      title: "Data Migration",
      innercontent: require("../Images/TechnicalServices/DataMigration.jpg"),
    },
    {
      image: require("../Images/Icons/DataBackup.png"),
      title: "Data Backup & Recovery",
      innercontent: require("../Images/TechnicalServices/DataBackup and Recovery.jpg"),
    },
    {
      image: require("../Images/Icons/DisasterDelivery.png"),
      title: "Disaster Data & Recovery",
      innercontent: require("../Images/TechnicalServices/Disaster Data and Recovery.jpg"),
    },
    {
      image: require("../Images/Icons/Redhat.png"),
      title: "Red Hat & C Panel Installation",
      innercontent: require("../Images/TechnicalServices/Red hat.jpg"),
    },
    {
      image: require("../Images/Icons/OSHardening.png"),
      title: "OS Hardening",
      innercontent: require("../Images/TechnicalServices/OSHardening.jpg"),
    },
    {  
      image: require("../Images/Icons/GamingSolutions.png"),
      title: "Gaming Solutions",
      innercontent: require("../Images/TechnicalServices/Gaming Solutions.jpg"),
    },
    {
      image: require("../Images/Icons/SupportMaintainance.png"),
      title: "Support & Maintainance",
      innercontent: require("../Images/TechnicalServices/Support and Maintanence.jpg"),
    },
  ],
];
export default outtechnicalservices;
