import React from "react";
import "../../Scss/Footer.scss";
import Logo from "../../Images/FooterLogo.png";
import FacebookLogo from "../../Images/Icons/facebook 1.png";
import XLogo from "../../Images/Icons/twitter 1.png";
import InstaLogo from "../../Images/Icons/instagram (3) 1.png";
import LQuotes from "../../Images/Icons/quote.png";
import RQuotes from "../../Images/Icons/double-quotes.png";
import Developer from '../../Images/Icons/sign1.png';
import Logo1 from '../../Images/Icons/Untitled-1.png'
import Logo2 from '../../Images/Icons/Untitled-2.png'
import Logo3 from '../../Images/Icons/Untitled-3.png'
import FooterData from "../../Data/Footer";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="ayfa_footer_section">
        <div className="container">
          <div className="ayfa_footer_contactdetails">
            <div className="row">
              {FooterData.map((data) => {
                return (
                  <div className="col-md-4">
                    <div className="ayfa_footer_contact_lists">
                      <div className="ayfa_footer_contacts">
                        <div className="ayfa_footer_logos">
                          <img src={data.image} alt="logo" />
                        </div>
                        <div className="ayfa_footer_details">
                          <h3>{data.title}</h3>
                          <h5>{data.content}</h5>
                          <h5>{data.content2}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="ayfa_footer_line" />
          <div>
            <div className="row">
              <div className="col-md-4">
                <div className="ayfa_footer_aboutpage">
                  <div className="ayfa_footer_aboutpage_footerlogo">
                    <div className="ayfa_footer_aboutpage_logoimg">
                      <img src={Logo} alt="logo" />
                    </div>
                  </div>

                  <div className="ayfa_footer_aboutpage_content">
                    <h6>
                      <img
                        className="ayfa_footer_Lquotes"
                        src={LQuotes}
                        alt="logo"
                      />
                      At Ayaan Faris Computers (AFC) We understand today’s
                      technological needs in the daily walks of life.
                      Accordingly through our business relationship with our
                      valued clients, the promise shall ever remain to maintain
                      the highest standard of customer satisfaction and
                      happiness through out our business journey together.
                      <img
                        src={RQuotes}
                        className="ayfa_footer_Lquotes"
                        alt="logo"
                      />
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ayfa_footer_usefullinks_section">
                  <div>
                    <div className="ayfa_footer_footertitle">
                      <h5>Useful Links</h5>
                    </div>
                    <hr className="ayfa_footer_footerline" />
                    <div className="ayfa_footer_footerlinks">
                      <ul>
                        <li>
                          <Link className="ayfa_footer_links" to="/">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="ayfa_footer_links"
                            to="/laptopdesktop"
                          >
                            Products
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="ayfa_footer_links"
                            to="/ourtechnicalservices"
                          >
                            Our Services
                          </Link>
                        </li>
                        <li>
                          <Link className="ayfa_footer_links" to="/brands">
                            Brands
                          </Link>
                        </li>
                        <li>
                          <Link className="ayfa_footer_links" to="/contactus">
                            Contact us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">

                <div className="ayfa_footer_subscribe_section">
                  <div>
                    <div className="ayfa_footer_subscribetitle">
                      <h5>Follow Us</h5>
                    </div>
                    <hr className="ayfa_footer_footerline_subscribe" />
                    <div className="ayfa_footer_subscribe_content">
                    </div>
                    <div className="ayfa_footer_socialmedia_content">
                      <div>
                        <a href="#">
                          <img src={FacebookLogo} alt="logo" />
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <img src={XLogo} alt="logo" />
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <img src={InstaLogo} alt="logo" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ayfa_footer_uae_logos">
                  <div className="ayfa_footer_uaelogos"><img src={Logo1} alt="logo" /></div>
                  <div className="ayfa_footer_uaelogos"><img src={Logo2} alt="logo" /></div>
                  <div className="ayfa_footer_uaelogos"><img src={Logo3} alt="logo" /></div>
                </div>
              </div>
            </div>
          </div>

          <div className="ayfa_copyright_section">
            <h4><b>AYFA Technologies, LLC&nbsp;&nbsp;&copy;&nbsp;&nbsp;</b>|&nbsp;&nbsp;Terms & Conditions&nbsp;&nbsp;|&nbsp;&nbsp;Privacy Policy</h4>
            <h5>Site developed by : <a href="#"><img src={Developer} /></a></h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
