import React, { useState } from "react";
import "../../Scss/OurTechnicalServices.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../Scss/Home.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop.js";
import OurTechnicalServicesData from "../../Data/OutTechnicalServices.js";
import logo1 from "../../Images/Icons/Laptop Servicing.png";
import logo2 from "../../Images/Icons/TelephonyPABX.png";
import logo3 from "../../Images/Icons/NetworkingSolutions.png";
import logo4 from "../../Images/Icons/StructuredCabling.png";
import logo5 from "../../Images/Icons/SurvellienceSecurity.png";
import logo6 from "../../Images/Icons/VideoConferencing.png";
import logo7 from "../../Images/Icons/AttendanceSystem.png";
import logo8 from "../../Images/Icons/ServerInstallation.png";
import logo9 from "../../Images/Icons/CloudStorage.png";
import logo10 from "../../Images/Icons/DataMigration.png";
import logo11 from "../../Images/Icons/DataBackup.png";
import logo12 from "../../Images/Icons/DisasterDelivery.png";
import logo13 from "../../Images/Icons/Redhat.png";
import logo14 from "../../Images/Icons/OSHardening.png";
import logo15 from "../../Images/Icons/GamingSolutions.png";
import logo16 from "../../Images/Icons/SupportMaintainance.png";

const OurTechnicalServices = () => {
  const [dialog1, setDialog1] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  const [dialog3, setDialog3] = useState(false);
  const [dialog4, setDialog4] = useState(false);
  const [dialog5, setDialog5] = useState(false);
  const [dialog6, setDialog6] = useState(false);
  const [dialog7, setDialog7] = useState(false);
  const [dialog8, setDialog8] = useState(false);
  const [dialog9, setDialog9] = useState(false);
  const [dialog10, setDialog10] = useState(false);
  const [dialog11, setDialog11] = useState(false);
  const [dialog12, setDialog12] = useState(false);
  const [dialog13, setDialog13] = useState(false);
  const [dialog14, setDialog14] = useState(false);
  const [dialog15, setDialog15] = useState(false);
  const [dialog16, setDialog16] = useState(false);

  const [imageSrc1, setImageSrc1] = useState(
    require("../../Images/TechnicalServices/LaptopRepair and Servicing.jpg")
  );
  const [imageSrc2, setImageSrc2] = useState(
    require("../../Images/TechnicalServices/IP Telephone and PABX.jpg")
  );
  const [imageSrc3, setImageSrc3] = useState(
    require("../../Images/TechnicalServices/Networking Solution.jpg")
  );
  const [imageSrc4, setImageSrc4] = useState(
    require("../../Images/TechnicalServices/Structured Cabling.jpg")
  );
  const [imageSrc5, setImageSrc5] = useState(
    require("../../Images/TechnicalServices/Surveillance and CCTV Security.jpg")
  );
  const [imageSrc6, setImageSrc6] = useState(
    require("../../Images/TechnicalServices/VideoConferencing and Solutions.jpg")
  );
  const [imageSrc7, setImageSrc7] = useState(
    require("../../Images/TechnicalServices/Attendance System and Access control.jpg")
  );
  const [imageSrc8, setImageSrc8] = useState(
    require("../../Images/TechnicalServices/ServerInstalling and Management.jpg")
  );
  const [imageSrc9, setImageSrc9] = useState(
    require("../../Images/TechnicalServices/Cloud Storage.jpg")
  );
  const [imageSrc10, setImageSrc10] = useState(
    require("../../Images/TechnicalServices/DataMigration.jpg")
  );
  const [imageSrc11, setImageSrc11] = useState(
    require("../../Images/TechnicalServices/DataBackup and Recovery.jpg")
  );
  const [imageSrc12, setImageSrc12] = useState(
    require("../../Images/TechnicalServices/Disaster Data and Recovery.jpg")
  );
  const [imageSrc13, setImageSrc13] = useState(
    require("../../Images/TechnicalServices/Red hat.jpg")
  );
  const [imageSrc14, setImageSrc14] = useState(
    require("../../Images/TechnicalServices/OSHardening.jpg")
  );
  const [imageSrc15, setImageSrc15] = useState(
    require("../../Images/TechnicalServices/Gaming Solutions.jpg")
  );
  const [imageSrc16, setImageSrc16] = useState(
    require("../../Images/TechnicalServices/Support and Maintanence.jpg")
  );

  const toggleDialog1 = () => {
    setDialog1(!dialog1);
  };
  const toggleDialog2 = () => {
    setDialog2(!dialog2);
  };
  const toggleDialog3 = () => {
    setDialog3(!dialog3);
  };
  const toggleDialog4 = () => {
    setDialog4(!dialog4);
  };
  const toggleDialog5 = () => {
    setDialog5(!dialog5);
  };
  const toggleDialog6 = () => {
    setDialog6(!dialog6);
  };
  const toggleDialog7 = () => {
    setDialog7(!dialog7);
  };
  const toggleDialog8 = () => {
    setDialog8(!dialog8);
  };
  const toggleDialog9 = () => {
    setDialog9(!dialog9);
  };
  const toggleDialog10 = () => {
    setDialog10(!dialog10);
  };
  const toggleDialog11 = () => {
    setDialog11(!dialog11);
  };
  const toggleDialog12 = () => {
    setDialog12(!dialog12);
  };
  const toggleDialog13 = () => {
    setDialog13(!dialog13);
  };
  const toggleDialog14 = () => {
    setDialog14(!dialog14);
  };
  const toggleDialog15 = () => {
    setDialog15(!dialog15);
  };
  const toggleDialog16 = () => {
    setDialog16(!dialog16);
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="ayfa_ourservices_bannerimg">
        {OurTechnicalServicesData[0].map((data) => {
          return <LazyLoadImage src={data.image} alt="" />;
        })}
      </div>

      <div className="container">
        <div className="ayfa_ourservices_title">
          {OurTechnicalServicesData[0].map((data) => {
            return <h4>{data.title}</h4>;
          })}
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog1}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo1} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Laptop Repair & Servicing</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog2}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo2} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>IP Telephony PABX</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog3}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo3} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Networking Solutions</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog4}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo4} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Structured Cabling</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog5}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo5} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Surveillance & Security CCTV</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog6}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo6} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Video Conferencing Solutions</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog7}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo7} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Attendance System & Access Control</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog8}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo8} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Server Installation & Management</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog9}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo9} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Cloud Storage</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog10}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo10} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Data Migration</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog11}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo11} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Data Backup & Recovery</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog12}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo12} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Disaster Data Recovery</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog13}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo13} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Red Hat & Cpanel Installation</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog14}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo14} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>OS Hardening</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog15}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo15} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Gaming Solutions</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="ayfa_ourservices_maincard" onClick={toggleDialog16}>
              <div className="ayfa_ourservices">
                <div>
                  <img src={logo16} />
                </div>
              </div>
              <div className="ayfa_ourservices_title">
                <h5>Support & Maintainance</h5>
              </div>
            </div>
          </div>
        </div>

        {dialog1 && (
          <div className="dialog-overlay" onClick={toggleDialog1}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >
                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc1}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog2 && (
          <div className="dialog-overlay" onClick={toggleDialog2}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc2}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog3 && (
          <div className="dialog-overlay" onClick={toggleDialog3}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc3}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog4 && (
          <div className="dialog-overlay" onClick={toggleDialog4}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc4}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog5 && (
          <div className="dialog-overlay" onClick={toggleDialog5}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc5}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog6 && (
          <div className="dialog-overlay" onClick={toggleDialog6}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc6}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog7 && (
          <div className="dialog-overlay" onClick={toggleDialog7}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc7}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog8 && (
          <div className="dialog-overlay" onClick={toggleDialog8}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc8}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog9 && (
          <div className="dialog-overlay" onClick={toggleDialog9}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc9}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog10 && (
          <div className="dialog-overlay" onClick={toggleDialog10}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc10}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog11 && (
          <div className="dialog-overlay" onClick={toggleDialog11}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc11}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog12 && (
          <div className="dialog-overlay" onClick={toggleDialog12}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc12}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog13 && (
          <div className="dialog-overlay" onClick={toggleDialog13}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc13}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog14 && (
          <div className="dialog-overlay" onClick={toggleDialog14}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc14}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog15 && (
          <div className="dialog-overlay" onClick={toggleDialog15}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc15}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}

        {dialog16 && (
          <div className="dialog-overlay" onClick={toggleDialog16}>
            <div className="dialog">
              <div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
              >

                <LazyLoadImage
                  className="popup-image"
                  src={imageSrc16}
                  alt="Popup Image"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="ayfa_techicalservice_footer">
        <Footer />
      </div>
    </>
  );
};

export default OurTechnicalServices;
