const meettheteamdatas = [
  {
    userimg:require("../Images/Icons/user-dummy.png"),
    position: "Creative Leader",
    name: "Lorem Ipsum",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis vestibulum nunc, eu blandit nibh. Done quis facilisis tortor. Etiam ultricies, ante dignissim tristique varius, dolor diam tempus purus, blandit mollis nisl turpis ut turpis. Mauris elementum id nisl eget vestibulum. Pellentesque at tincidunt felis.",
    link1: "",
    link2: "",
    link3: "",
  },
  {
    userimg:require("../Images/Icons/user-dummy.png"),
    position: "Creative Leader",
    name: "Lorem Ipsum",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis vestibulum nunc, eu blandit nibh. Done quis facilisis tortor. Etiam ultricies, ante dignissim tristique varius, dolor diam tempus purus, blandit mollis nisl turpis ut turpis. Mauris elementum id nisl eget vestibulum. Pellentesque at tincidunt felis.",
    link1: "",
    link2: "",
    link3: "",
  }
];
export default meettheteamdatas
