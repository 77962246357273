import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../Scss/LaptopDesktop.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import LaptopDesktopData from "../../Data/LaptopDesktop";

const LaptopDesktop = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="ayfa_LaptopsDesktops_main">
        <div className="container">
          <div className="ayfa_LaptopsDesktops_title">
            <h3>ENTERPRISE & CONSUMER PRODUCTS</h3>
            <h5>Monitors</h5>
          </div>

          <div className="row">
            {LaptopDesktopData[2].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ayfa_Desktop_title">
            <h5>Laptops</h5>
          </div>

          <div className="row">
            {LaptopDesktopData[0].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_Desktop_title">
            <h5>Desktops</h5>
          </div>
          <div className="row">
            {LaptopDesktopData[1].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_Desktop_title">
            <h5>Digital Signage Display</h5>
          </div>
          <div className="row">
            {LaptopDesktopData[3].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_Desktop_title">
            <h5>Televisions</h5>
          </div>
          <div className="row">
            {LaptopDesktopData[4].map((data) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_Desktop_title">
            <h5>Mobile Phones and Tablets</h5>
          </div>
          <div className="row">
            {LaptopDesktopData[5].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LaptopDesktop;
