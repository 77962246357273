import React from "react";
import "../../Scss/LaptopDesktop.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop.js";
import ComponentsData from "../../Data/Components.js";
const Components = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="ayfa_LaptopsDesktops_main">
        <div className="container">

          <div className="ayfa_LaptopsDesktops_title">
            <h3>COMPONENTS</h3>
            <h5>MotherBoards and Processors</h5>
          </div>
          <div className="row">
            {ComponentsData[4].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>


          <div className="ayfa_Desktop_title">
            <h5>Graphics Cards</h5>
          </div>
          <div className="row">
            {ComponentsData[3].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>


          <div className="ayfa_Desktop_title">
            <h5>Cooling Fans</h5>
          </div>
          <div className="row">
            {ComponentsData[0].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ayfa_Desktop_title">
            <h5>Power Supplies</h5>
          </div>
          <div className="row">
            {ComponentsData[1].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ayfa_Desktop_title">
            <h5>CPU Cabinets</h5>
          </div>
          <div className="row">
            {ComponentsData[2].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_LaptopDesktops_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Components;
