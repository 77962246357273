const PeripheralsAccessories = [
  [
    { image: require("../Images/LaptopDesktop/Cables1.png") },
    { image: require("../Images/LaptopDesktop/Cables2.png") },
    { image: require("../Images/LaptopDesktop/Cables3.png") },
    { image: require("../Images/LaptopDesktop/Cables4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/webcam1.png") },
    { image: require("../Images/LaptopDesktop/webcam2.png") },
    { image: require("../Images/LaptopDesktop/webcam3.png") },
    { image: require("../Images/LaptopDesktop/webcam4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Headset1.png") },
    { image: require("../Images/LaptopDesktop/Headset2.png") },
    { image: require("../Images/LaptopDesktop/Headset3.png") },
    { image: require("../Images/LaptopDesktop/Headset4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/DockingStations1.png") },
    { image: require("../Images/LaptopDesktop/DockingStations2.png") },
    { image: require("../Images/LaptopDesktop/DockingStations3.png") },
    { image: require("../Images/LaptopDesktop/DockingStations4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Keyboard1.png") },
    { image: require("../Images/LaptopDesktop/Mouse1.png") },
    { image: require("../Images/LaptopDesktop/Mouse2.png") },
    { image: require("../Images/LaptopDesktop/Keyboard2.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Wireless2.png") },
    { image: require("../Images/LaptopDesktop/Wireless1.png") },
    { image: require("../Images/LaptopDesktop/Wireless3.png") },   
    { image: require("../Images/LaptopDesktop/Wireless4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Bag1.png") },
    { image: require("../Images/LaptopDesktop/Bag2.png") },
    { image: require("../Images/LaptopDesktop/Bag3.png") },
    { image: require("../Images/LaptopDesktop/Bag4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Printer1.png") },
    { image: require("../Images/LaptopDesktop/Printer2.png") },
    { image: require("../Images/LaptopDesktop/Printer3.png") },
    { image: require("../Images/LaptopDesktop/Printer4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Projector1.png") },
    { image: require("../Images/LaptopDesktop/Projector2.png") },
    { image: require("../Images/LaptopDesktop/Projector3.png") },
    { image: require("../Images/LaptopDesktop/Projector4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Photocopies1.png") },
    { image: require("../Images/LaptopDesktop/Photocopies2.png") },
    { image: require("../Images/LaptopDesktop/Photocopies3.png") },
    { image: require("../Images/LaptopDesktop/Photocopies4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Monitors1.png") },
    { image: require("../Images/LaptopDesktop/Monitors2.png") },
    { image: require("../Images/LaptopDesktop/Monitors3.png") },
    { image: require("../Images/LaptopDesktop/Monitors4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/DigitalSignageDisplay1.png") },
    { image: require("../Images/LaptopDesktop/DigitalSignageDisplay2.png") },
    { image: require("../Images/LaptopDesktop/DigitalSignageDisplay3.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/Televisions1.png") },
    { image: require("../Images/LaptopDesktop/Televisions2.png") },
    { image: require("../Images/LaptopDesktop/Televisions3.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/HDMI1.png") },
    { image: require("../Images/LaptopDesktop/HDMI2.png") },
    { image: require("../Images/LaptopDesktop/HDMI3.png") },
    { image: require("../Images/LaptopDesktop/HDMI4.png") },
  ],
];
export default PeripheralsAccessories;
