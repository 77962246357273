const LaptopDesktop = [
  [
 
    {
      image: require("../Images/LaptopDesktop/lapimg3.png"),
    },
    {
      image: require("../Images/LaptopDesktop/lapimg4.png"),
    },
    {
      image: require("../Images/LaptopDesktop/lapimg5.png"),
    },
    {
      image: require("../Images/LaptopDesktop/lapimg9.png"),
    },

  ],
  [
    {
      image: require("../Images/LaptopDesktop/Desktop1.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Desktop2.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Desktop3.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Desktop4.png"),
    },
  ],
  [
    {
      image: require("../Images/LaptopDesktop/Monitors1.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Monitors2.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Monitors3.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Monitors4.png"),
    }
    
  ],

  [
    {
      image: require("../Images/LaptopDesktop/DigitalSignageDisplay1.png"),
    },
    {
      image: require("../Images/LaptopDesktop/DigitalSignageDisplay2.png"),
    },
    {
      image: require("../Images/LaptopDesktop/DigitalSignageDisplay3.png"),
    },
    {
      image: require("../Images/LaptopDesktop/DigitalSignageDisplay4.png"),
    }
   
    
  ],
  [
    {
      image: require("../Images/LaptopDesktop/Televisions1.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Televisions2.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Televisions3.png"),
    }
   
    
  ],

  [
    {
      image: require("../Images/LaptopDesktop/Mobiles1.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Mobiles2.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Mobiles3.png"),
    },
    {
      image: require("../Images/LaptopDesktop/Mobiles4.png"),
    }
   
    
  ],

];
export default LaptopDesktop;
