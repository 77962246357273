import React from "react";
import "../../Scss/Brands.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop.js";
import LQuote from "../../Images/Icons/quote.png";
import RQuote from "../../Images/Icons/double-quotes.png";
import BrandsData from "../../Data/Brands";
const Brands = () => {
  return (
    <>
      <ScrollToTop/>
      <Header />
      <div className="ayfa_brands_bannerimg">
        {BrandsData[0].map((data) => {
          return <LazyLoadImage src={data.image} alt="logo"/>;
        })}
      </div>
      <div className="container">
        <div>
          {BrandsData[1].map((data) => {
            return (
              <div className="ayfa_brands_title">
                <h3>{data.title}</h3>
                <p>
                  <img src={LQuote} className="ayfa_LQuote" alt="logo" />
                  {data.content}
                  <img src={RQuote} className="ayfa_RQuote" alt="logo" />
                </p>
              </div>
            );
          })}
        </div>
        <div class="ayfa_brands_slider">   
          <div class="ayfa_slide_track">
            {BrandsData[2].map((data) => {
              return (
                <div class="ayfa_brand_slide">
                  <LazyLoadImage src={data.image} height="100" width="250" alt="logo" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Brands;
