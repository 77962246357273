const Footer = [
  {
    image: require("../Images/Icons/map (2).png"),
    title: "Find us",
    content: " Off.No - 202-a3 Al Marri Building",
    content2: " Al Mararr Deira P.O Box: 475761",
  },
  {
    image: require("../Images/Icons/phone-call (1) 2.png"),
    title: "Call us",
    content: "+971 501578598 | +971 505137992",
  },
  {
    image: require("../Images/Icons/email (3) 1.png"),
    title: "Mail us",
    content: "sales@afcomputech.com",
  },
];
export default Footer;
