const BrandsData = [
  {
    image: require("../../Images/Brands/Logo01.png"),
  },
  {
    image: require("../../Images/Brands/Logo02.png"),
  },
  {
    image: require("../../Images/Brands/Logo03.png"),
  },
  {
    image: require("../../Images/Brands/Logo04.png"),
  },
  {
    image: require("../../Images/Brands/Logo05.png"),
  },
  {
    image: require("../../Images/Brands/Logo06.png"),
  },
  {
    image: require("../../Images/Brands/Logo07.png"),
  },
  {
    image: require("../../Images/Brands/Logo08.png"),
  },
  {
    image: require("../../Images/Brands/Logo09.png"),
  },
  {
    image: require("../../Images/Brands/Logo010.png"),
  },
  {
    image: require("../../Images/Brands/Logo011.png"),
  },
  {
    image: require("../../Images/Brands/Logo012.png"),
  },
  {
    image: require("../../Images/Brands/Logo013.png"),
  },
  {
    image: require("../../Images/Brands/Logo014.png"),
  },
  {
    image: require("../../Images/Brands/Logo015.png"),
  },
  {
    image: require("../../Images/Brands/Logo016.png"),
  },
  {
    image: require("../../Images/Brands/Logo017.png"),
  },
  {
    image: require("../../Images/Brands/Logo018.png"),
  },
  {
    image: require("../../Images/Brands/Logo019.png"),
  },
  {
    image: require("../../Images/Brands/Logo020.png"),
  },
  {
    image: require("../../Images/Brands/Logo021.png"),
  },
  {
    image: require("../../Images/Brands/Logo022.png"),
  },
  {
    image: require("../../Images/Brands/Logo023.png"),
  },
  {
    image: require("../../Images/Brands/Logo024.png"),
  },
  {
    image: require("../../Images/Brands/Logo025.png"),
  },
  {
    image: require("../../Images/Brands/Logo026.png"),
  },
  {
    image: require("../../Images/Brands/Logo027.png"),
  },
  {
    image: require("../../Images/Brands/Logo028.png"),
  },
  {
    image: require("../../Images/Brands/Logo029.png"),
  },
  {
    image: require("../../Images/Brands/Logo030.png"),
  },
  {
    image: require("../../Images/Brands/Logo031.png"),
  },
  {
    image: require("../../Images/Brands/Logo032.png"),
  },
  {
    image: require("../../Images/Brands/Logo033.png"),
  },
  {
    image: require("../../Images/Brands/Logo034.png"),
  },
  {
    image: require("../../Images/Brands/Logo035.png"),
  },
  {
    image: require("../../Images/Brands/Logo036.png"),
  },
  {
    image: require("../../Images/Brands/Logo037.png"),
  },
  {
    image: require("../../Images/Brands/Logo038.png"),
  },
  {
    image: require("../../Images/Brands/Logo039.png"),
  },
  {
    image: require("../../Images/Brands/Logo040.png"),
  },
  {
    image: require("../../Images/Brands/Logo041.png"),
  },
  {
    image: require("../../Images/Brands/Logo042.png"),
  },
  {
    image: require("../../Images/Brands/Logo043.png"),
  },
  {
    image: require("../../Images/Brands/Logo044.png"),
  },
  {
    image: require("../../Images/Brands/Logo045.png"),
  },
  {
    image: require("../../Images/Brands/Logo046.png"),
  },
  {
    image: require("../../Images/Brands/Logo047.png"),
  },
  {
    image: require("../../Images/Brands/Logo048.png"),
  },
  {
    image: require("../../Images/Brands/Logo049.png"),
  },
  {
    image: require("../../Images/Brands/Logo050.png"),
  },
  {
    image: require("../../Images/Brands/Logo051.png"),
  },
  {
    image: require("../../Images/Brands/Logo052.png"),
  },
  {
    image: require("../../Images/Brands/Logo053.png"),
  },
  {
    image: require("../../Images/Brands/Logo054.png"),
  },

];
export default BrandsData;
