import React from 'react'
import "../../Scss/LaptopDesktop.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop.js";
import GamingData from '../../Data/Gaming.js'
const Gaming = () => {
  return (
    <>
      <ScrollToTop/>
    <Header />
    <div className='ayfa_LaptopsDesktops_main'>
      <div className="container">
        <div className="ayfa_LaptopsDesktops_title">
          <h3>GAMING</h3>
          <h5>Computer Cases</h5>
        </div>
        <div className="row">
          {GamingData[0].map((data) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="ayfa_LaptopDesktops_image_content">
                  <LazyLoadImage src={data.image} alt="logo"/>
                </div>
              </div>
            );
          })}
        </div>
        <div className="ayfa_Desktop_title">
          <h5>Gaming Chairs</h5>
        </div>
        <div className="row">
          {GamingData[1].map((data) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="ayfa_LaptopDesktops_image_content">
                  <LazyLoadImage src={data.image} alt="logo" />
                </div>
              </div>
            );
          })}
        </div>

        <div className="ayfa_Desktop_title">
          <h5>Gaming Headsets</h5>
        </div>
        <div className="row">
          {GamingData[2].map((data) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="ayfa_LaptopDesktops_image_content">
                  <LazyLoadImage src={data.image} alt="logo"/>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    <Footer />
  </>
  )
}

export default Gaming
