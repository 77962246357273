import React from "react";
import "../../Scss/Aboutus.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop.js";
import AboutUsData from "../../Data/AboutUs.js";
const Aboutus = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="ayfa_aboutus_bannerimg">
        {AboutUsData[0].map((data) => {
          return <LazyLoadImage src={data.image} />;
        })}
      </div>
      <div className="container">

        {AboutUsData[0].map((data) => {
          return (
            <div className="ayfa_aboutus_title">
              <h3>{data.title}</h3>
              <p>

                {data.content}
              </p>
            </div>
          );
        })}

        <div className="ayfa_aboutus_whoweare">
          <div className="row">
            <div className="col-lg-6">
              {AboutUsData[1].map((data) => {
                return (
                  <div className="ayfa_aboutus_whoweare_content">
                    <h3>{data.title}</h3>
                    <p>{data.content}</p>
                  </div>
                );
              })}
            </div>
            <div className="col-lg-6">
              {AboutUsData[1].map((data) => {
                return (
                  <div className="ayfa_aboutus_whoweare_image">
                    <LazyLoadImage src={data.image} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="ayfa_aboutus_whywechooseus">
          {
            AboutUsData[2].map((data) => {
              return (
                <div className="ayfa_aboutus_whychooseus_title">
                  <h3>{data.title}</h3>
                </div>
              )
            })
          }

        </div>
        <div className="row">
          {
            AboutUsData[3].map((data) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="ayfa_aboutus_whychooseus_main">
                    <div className="ayfa_aboutus_whychooseus_img">
                      <img src={data.image} />
                    </div>
                    <div className="ayfa_aboutus_whychooseus_title">
                      <h4>{data.title}</h4>
                      <p>{data.content}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Aboutus;
