import React from "react";
import "../../Scss/MeetTheTeam.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Facebook from "../../Images/Icons/facebook 1.png";
import Xlogo from "../../Images/Icons/twitter 1.png";
import Instagram from "../../Images/Icons/instagram (3) 1.png";
import BannerImg from "../../Images/Sliders/MeetTheTeam.jpeg";
import MeetTheTeamData from "../../Data/MeetTheTeam";

const MeetTheTeam = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="ayfa_meetteam_bannerImg">
        <LazyLoadImage src={BannerImg} />
      </div>
      <div className="container">
        <div className="ayfa_meettheteam">
          <div className="ayfa_meettheteam_title">
            <h4>Meet the Team</h4>
          </div>
          <div className="row">
            {MeetTheTeamData.map((data) => {
              return (
                <div className="col-lg-6">
                  <div className="ayfa_meettheteam_column_content">
                    <div className="ayfa_meettheteam_content">
                      <div className="ayfa_meettheteam_img">
                        <LazyLoadImage src={data.userimg} alt="" />
                      </div>
                      <div>
                        <div className="ayfa_meettheteam_content_details">
                          <h6>{data.position}</h6>
                          <h5>{data.name}</h5>    
                          <p>
                            {data.summary}
                          </p>   
                          <div className="ayfa_meettheteam_sociallinks">
                            <div>
                              <img src={Facebook} />
                            </div>
                            <div>
                              <img src={Xlogo} />
                            </div>
                            <div>
                              <img src={Instagram} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MeetTheTeam;
