const Storage = [
  [
    { image: require("../Images/LaptopDesktop/RAM1.png") },
    { image: require("../Images/LaptopDesktop/RAM2.png") },
    { image: require("../Images/LaptopDesktop/RAM3.png") },
    { image: require("../Images/LaptopDesktop/RAM4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/HDD1.png") },
    { image: require("../Images/LaptopDesktop/HDD2.png") },
    { image: require("../Images/LaptopDesktop/HDD3.png") },
    { image: require("../Images/LaptopDesktop/HDD4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/PHDD1.png") },
    { image: require("../Images/LaptopDesktop/PHDD2.png") },
    { image: require("../Images/LaptopDesktop/PHDD3.png") },
    { image: require("../Images/LaptopDesktop/PHDD4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/USB1.png") },
    { image: require("../Images/LaptopDesktop/USB2.png") },
    { image: require("../Images/LaptopDesktop/USB3.png") },
    { image: require("../Images/LaptopDesktop/USB4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/SD1.png") },
    { image: require("../Images/LaptopDesktop/SD2.png") },
    { image: require("../Images/LaptopDesktop/SD3.png") },
    { image: require("../Images/LaptopDesktop/SD4.png") },
  ],
  [
    { image: require("../Images/LaptopDesktop/CardReader1.png") },
    { image: require("../Images/LaptopDesktop/CardReader2.png") },
    { image: require("../Images/LaptopDesktop/CardReader3.png") },
    { image: require("../Images/LaptopDesktop/CardReader4.png") },
  ],
];
export default Storage;
