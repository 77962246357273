const contactus = [
  {
    icon: require("../Images/Icons/placeholder.png"),
    title: "Address",
    content1: "Off.No - 202-a3 Al Marri Building",
    content2: "Al Mararr Deira P.O Box: 475761",
  },
  {
    icon: require("../Images/Icons/email.png"),
    title: "E-Mail",
    content1: "sales@afcomputech.com",
    content2: "+971 501578598 / +971 505137992",
  },
  {
    icon: require("../Images/Icons/clock.png"),
    title: "Office Time",
    content1: "Mon - Thu 8:00 am - 5.00 pm",
    content2: "Fri - 8.00 am - 2.00 pm",
    content3: "Sat - Sun - Closed",
  },
];
export default contactus;

// #1CC6BB
