import React from "react";
import "../../Scss/PeripheralsAccessories.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import PeripheralsAccessoriesData from "../../Data/PeripheralsAccessories";
const PeripheralsAccessories = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="ayfa_peripherals_main">
        <div className="container">
          <div className="ayfa_peripheralsaccessories_title">
            <h3>PERIPHERALS AND ACCESSORIES</h3>
            <h5>HDMI and Networking Cables</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[13].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Laptop Chargers</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[0].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Keyboards & Mouse</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[4].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Webcams</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[1].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Call Center Headsets with Mic</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[2].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Docking Stations</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[3].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Wireless and Networking Devices</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[5].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Laptop Cases and Bags</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[6].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ayfa_webcams_title">
            <h5>Office Printers</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[7].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ayfa_webcams_title">
            <h5>Conference Room Projectors</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[8].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ayfa_webcams_title">
            <h5>Photocopiers</h5>
          </div>
          <div className="row">
            {PeripheralsAccessoriesData[9].map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="ayfa_PeripheralsAccessories_image_content">
                    <LazyLoadImage src={data.image} alt="logo" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PeripheralsAccessories;
