import React, { useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../Scss/Carousal.scss";
import Slider1 from "../../Images/Sliders/slider1.jpg";
import Slider2 from "../../Images/Sliders/slider2.jpg";
import Slider3 from "../../Images/Sliders/slider3.jpg";
import Slider4 from "../../Images/Sliders/slider4.jpg";
import Slider5 from "../../Images/Sliders/slider5.jpg";
import Slider6 from "../../Images/Sliders/slider6.jpg";

const Carousal = () => {
  useEffect(() => {
    const mainBannerCarousel = document.getElementById("mainBanner");
    new window.bootstrap.Carousel(mainBannerCarousel);
  }, []);

  return (
    <>     
      <div class="ayfa_carousal_main">
        <div class="main-header pt-lg-3 pt-2 position-absolute container-fluid">
          <nav class="d-flex justify-content-between align-items-center px-md-4 px-2">
            <div class="mobile-menu d-block d-sm-none">
              <a class="nav-link link-light">
                <i class="fa-solid fa-bars"></i>
              </a>
            </div>
          </nav>
        </div>
        <div
          id="mainBanner"
          class="carousel slide"
          data-bs-ride="carousel"
          data-bs-pause="false"
        >
          <div class="carousel-inner">
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#mainBanner"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#mainBanner"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#mainBanner"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#mainBanner"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#mainBanner"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
              <button
                type="button"
                data-bs-target="#mainBanner"
                data-bs-slide-to="5"
                aria-label="Slide 6"
              ></button>
            </div>

            <div class="carousel-item active" data-bs-interval="8000">
              <LazyLoadImage src={Slider1} class="d-block w-100" alt="..." />
              <div class="carousel-caption px-md-5">
                <h5>
                  Upgrade your processes and <br /> Accepts the shifts of
                  <br />
                  <span> Innovation</span>
                </h5>
                <hr class="bg-light border-4 border-top border-light" />
              </div>
            </div>

            <div class="carousel-item" data-bs-interval="8000">
              <LazyLoadImage src={Slider2} class="d-block w-100" alt="..." />
              <div class="carousel-caption px-md-5">
                <h5>
                  We Supply IT Products to <br />
                  <span>Corporate Companies</span>
                </h5>
                <hr class="bg-light border-4 border-top border-light" />
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <LazyLoadImage src={Slider3} class="d-block w-100" alt="..." />
              <div class="carousel-caption px-md-5">
                <h5>
                  We Supply IT Products to
                  <br /> <span>Government and Semi Government entities</span>
                </h5>
                <hr class="bg-light border-4 border-top border-light" />
              </div>
            </div>

            <div class="carousel-item" data-bs-interval="8000">
              <LazyLoadImage src={Slider4} class="d-block w-100" alt="..." />
              <div class="carousel-caption px-md-5">
                <h5>
                  We Supply IT Products to
                  <br /> <span>Schools & Universities & Colleges</span>
                </h5>
                <hr class="bg-light border-4 border-top border-light" />
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <LazyLoadImage
                src={Slider5}
                class="d-block w-100"
                alt="..."
              />
              <div class="carousel-caption px-md-5">
                <h5>
                  We Supply IT Products to
                  <br /> <span>Banking Sectors</span>
                </h5>
                <hr class="bg-light border-4 border-top border-light" />
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <LazyLoadImage
                src={Slider6}
                class="d-block w-100"
                alt="..."
              />
              <div class="carousel-caption px-md-5">
                <h5>
                  We Supply IT Products to
                  <br /> <span>Hospitality Industries</span>
                </h5>
                <hr class="bg-light border-4 border-top border-light" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousal;
