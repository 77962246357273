const Components = [
    [
      { image: require("../Images/LaptopDesktop/CpuFan1.png") },
      { image: require("../Images/LaptopDesktop/CpuFan2.png") },
      { image: require("../Images/LaptopDesktop/CpuFan3.png") },
      { image: require("../Images/LaptopDesktop/CpuFan4.png") },
    ],
    [
      { image: require("../Images/LaptopDesktop/PowerSupply1.png") },
      { image: require("../Images/LaptopDesktop/PowerSupply2.png") },
      { image: require("../Images/LaptopDesktop/PowerSupply3.png") },
      { image: require("../Images/LaptopDesktop/PowerSupply4.png") },
    ],
    [
      { image: require("../Images/LaptopDesktop/DesktopCabinet1.png") },
      { image: require("../Images/LaptopDesktop/DesktopCabinet2.png") },
      { image: require("../Images/LaptopDesktop/DesktopCabinet3.png") },
      { image: require("../Images/LaptopDesktop/DesktopCabinet4.png") },
    ],
    [
      { image: require("../Images/LaptopDesktop/GraphicsCard1.png") },
      { image: require("../Images/LaptopDesktop/GraphicsCard2.png") },
      { image: require("../Images/LaptopDesktop/GraphicsCard3.png") },
      { image: require("../Images/LaptopDesktop/GraphicsCard4.png") },
    ],
    [
      { image: require("../Images/LaptopDesktop/Motherboard1.png") },
      { image: require("../Images/LaptopDesktop/Motherboard2.png") },
      { image: require("../Images/LaptopDesktop/Motherboard3.png") },
      { image: require("../Images/LaptopDesktop/Motherboard4.png") },
    ]
  ];
  export default Components;
  