import React from "react";
import "../../Scss/Header.scss";
import Headerlogo from "../../Images/FooterLogo.png";
import telephonelogo from '../../Images/Icons/telephone (1).png'
import maillogo from '../../Images/Icons/mail.png'
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <div className="ayfa_header_main">
        <div>
          <div className="ayfa_header_title">
            <img src={Headerlogo} alt="logo" />
            <h3>AYAAN&nbsp;&nbsp;FARIS&nbsp;&nbsp;COMPUTERS&nbsp;&nbsp;&&nbsp;&nbsp;REQUISITES&nbsp;&nbsp;TRADING&nbsp;&nbsp;CO.&nbsp;&nbsp;LLC</h3>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark ayfa_header_bg" >
          <div className="container">
            <a className="navbar-brand ayfa_header_contact" href='.'>
             <img src={maillogo} alt='logo'/>&nbsp;sales@afcomputech.com <br /><img src={telephonelogo} alt="logo"/>&nbsp;
              +971 501578598 | +971 505137992
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto ayfa_navlinks">
                <li className="nav-item">
                  <a className="nav-link active " aria-current="page">
                    <Link to="/">Home</Link>
                  </a>
                </li>

                <li className="nav-item">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle ayfa_dropdown_btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      About
                    </button>
                    <ul className="dropdown-menu ayfa_dropdown_menu">
                      <li>
                        <a className="dropdown-item" href="">
                          <Link to="/aboutus">About the Company</Link>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          <Link to="/meettheteam">
                            Meet the Team
                          </Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle ayfa_dropdown_btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Products
                    </button>
                    <ul className="dropdown-menu ayfa_dropdown_menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          <Link to="/laptopdesktop">Enterprise & Consumer Products</Link>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <Link to="/peripheralsaccessories">
                            Peripherals & Accessories
                          </Link>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <Link to="/storage">Enterprise Storage & Personal Storage</Link>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <Link to="/components">Components</Link>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <Link to="/gaming">Gaming</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <a className="nav-link">
                    <Link to="/brands">Brands</Link>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link ">
                    <Link to="/ourtechnicalservices">Our Services</Link>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link ">
                    <Link to="/contactus">Contact us</Link>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
